import React from 'react';
import { load } from '../helpers';

const EditOrderScene = load(React.lazy(() => import('./EditOrderScene/EditOrderScene')));
const AccountScene = load(React.lazy(() => import('./AccountScene/AccountScene')));
const LoginScene = load(React.lazy(() => import('./LoginScene/LoginScene')));
const NotFound = load(React.lazy(() => import('./NotFound/NotFound')));
const NewProductScene = load(React.lazy(() => import('./NewProductScene')));
const PersonalProductScene = load(React.lazy(() => import('./NewProductScene/PersonalProductScene')));
const AboutScene = load(React.lazy(() => import('./AboutScene/AboutScene')));
const LoginByUrlScene = load(React.lazy(() => import('./LoginByUrlScene/LoginByUrlScene')));
const ProblemScene = load(React.lazy(() => import('./ProblemScene/ProblemScene')));
const SandboxScene = load(React.lazy(() => import('./SandboxScene/SandboxScene')));
const OrderScene = load(React.lazy(() => import('./OrderScene/OrderScene')));
const ContactScene = load(React.lazy(() => import('./ContactScene/ContactScene')));
const TermsOfServicesScene = load(React.lazy(() => import('./TermOfServicesScene/TermOfServicesScene')));
const PrivacyPolicyScene = load(React.lazy(() => import('./PrivacyPolicyScene/PrivacyPolicyScene')));
const ProducerInfoScene = load(React.lazy(() => import('./InformationPages/ProducerInfoScene')));
const AboutInfoScene = load(React.lazy(() => import('./InformationPages/AboutInfoScene')));
const MarketOrganizersInfoScene = load(React.lazy(() => import('./InformationPages/MarketOrganizersInfoScene')));
const CreateOrResetPasswordScene = load(React.lazy(() => import('./CreateOrResetPasswordScene')));
const MarketsScene = load(React.lazy(() => import('./MarketsScene')));
const ProducersScene = load(React.lazy(() => import('./ProducersScene')));
const ProductsTypesScene = load(React.lazy(() => import('./ProductsTypesScene')));
const ProductsTypeScene = load(React.lazy(() => import('./ProductsTypeScene')));
const MarketScene = load(React.lazy(() => import('./MarketScene')));
const ProducerScene = load(React.lazy(() => import('./ProducerScene')));
const ProductsSubTypeScene = load(React.lazy(() => import('./ProductsSubTypeScene')));
const SignInViaLinkScene = load(React.lazy(() => import('./SignInViaLinkScene')));
const TrackOrderScene = load(React.lazy(() => import('./TrackOrderScene')));
const HomeScene = load(React.lazy(() => import('./HomeScene/HomeScene')));
const SubscriptionScene = load(React.lazy(() => import('./SubscriptionScene')));
const InviteScene = load(React.lazy(() => import('./InviteScene')));
const PersonalScene = load(React.lazy(() => import('./PersonalScene')));
const QrCodeScene = load(React.lazy(() => import('./QrCodeScene')));

export {
  AccountScene,
  EditOrderScene,
  LoginScene,
  NotFound,
  NewProductScene,
  PersonalProductScene,
  AboutScene,
  LoginByUrlScene,
  ProblemScene,
  SandboxScene,
  OrderScene,
  ContactScene,
  TermsOfServicesScene,
  PrivacyPolicyScene,
  ProducerInfoScene,
  CreateOrResetPasswordScene,
  MarketScene,
  MarketsScene,
  ProductsTypesScene,
  ProducersScene,
  ProducerScene,
  ProductsTypeScene,
  ProductsSubTypeScene,
  SignInViaLinkScene,
  AboutInfoScene,
  MarketOrganizersInfoScene,
  TrackOrderScene,
  HomeScene,
  SubscriptionScene,
  InviteScene,
  PersonalScene,
  QrCodeScene,
};
