/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * @returns {object}
 */
export const getDeviceInfo = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let results = {
    isWindowsPhone: false,
    isAndroid: false,
    isIOS: false,
  };

  if (/windows phone/i.test(userAgent)) {
    results.isWindowsPhone = true;
    return results;
  }

  if (/android/i.test(userAgent)) {
    results.isAndroid = true;
    return results;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    results.isIOS = true;
    return results;
  }

  return results;
};

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
