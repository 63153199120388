import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Router } from 'react-router';
import { Router, Route, Redirect } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { createBrowserHistory } from 'history';
import { CUSTOMER_TYPES, getToken, isJWTExpired, isVisited } from '../helpers';
import { Spinner, FellowFarmerDialog, MobileFooterMenu, GlobalFooter, MainScreenSwitcher } from '../components';
import { WelcomeBack } from '../scenes/Partials';
import { PrivateRoute } from '../routes';
import { hideWelcome, draftOpen, signOut, updateDraftError, draftOpenUnavailableItemsDialog } from '../store/actions';
import { OrderEntity } from '../entities';
import {
  EditOrderScene,
  AccountScene,
  NewProductScene,
  PersonalProductScene,
  NotFound,
  AboutScene,
  LoginByUrlScene,
  ProblemScene,
  SandboxScene,
  OrderScene,
  ContactScene,
  PrivacyPolicyScene,
  TermsOfServicesScene,
  ProducerInfoScene,
  CreateOrResetPasswordScene,
  MarketsScene,
  MarketScene,
  ProducersScene,
  ProductsTypesScene,
  ProductsTypeScene,
  ProductsSubTypeScene,
  SignInViaLinkScene,
  AboutInfoScene,
  MarketOrganizersInfoScene,
  TrackOrderScene,
  InviteScene,
  QrCodeScene,
} from '../scenes';
import {
  ChangeLocationProblemDialog,
  ProducersDialog,
  LoginAndSignUpDialog,
  UnavailableItemsDialog,
  CheckoutDialog,
  CartDialog,
  ClearShippingOptionsDialog,
  ForceLogInAndSignUpDialog,
  ErrorSubscriptionDialog,
  InfoDialog,
  RightSideMenuDialog,
  ProducerScreenSwitcher,
  PersonalScreenSwitcher,
  SentryErrorHandler,
} from '../containers';
import { apiDraftGetLastError } from '../api';
import SubscriptionScene from '../scenes/SubscriptionScene';
import { Hidden } from '@material-ui/core';
import PreviewScene from '../scenes/PreviewScene';

// Create history object
const history = createBrowserHistory();
// Listen to history changes.
const historyListener = (store) => {
  history.listen(() => {
    const token = getToken();
    // Check whether token is expired on not
    if (token && isJWTExpired(token)) {
      // Sign out current customer & sign in it as guest
      store.dispatch(signOut());
    }
  });
};

const PAGES_WITHOUT_FOOTER = [
  '/producer-info',
  '/about-info',
  '/organizers-info',
  'qr',
  'track-order', // any url that contain this match
];
const PAGES_WITHOUT_MENU = ['/producer-info', '/about-info', '/organizers-info', 'qr'];

const PAGES_WITHOUT_LOADER = ['/markets', '/market', '/producers', '/producer', '/product', '/products'];

class RouterWrapper extends React.Component {
  componentDidMount() {
    historyListener(this.props.store);

    history.listen(() => {
      //set page to top
      window.scrollTo(0, 0);

      this.getError();
    });

    this.getError();
  }

  getError = () => {
    const { updateDraftError, draftOpenUnavailableItemsDialog, draftOpen, isPersonalProducerSelect } = this.props;

    if (!isPersonalProducerSelect) {
      apiDraftGetLastError(
        (response) => {
          updateDraftError(response.data);
          if (response.data.code) {
            draftOpen(() => {
              draftOpenUnavailableItemsDialog();
            });
          }
        },
        (response) => {
          console.log('Something went wrong', response);
        }
      );
    }
  };

  isShowMobileMenu = () => {
    const { width } = this.props;

    if (!isVisited() && history.location.pathname === '/') {
      return false;
    }

    if (isWidthDown('sm', width)) {
      // dont show in main landing page

      return (
        !PAGES_WITHOUT_MENU.some((path) => history.location.pathname.split('/').includes(path)) &&
        !PAGES_WITHOUT_MENU.includes(history.location.pathname)
      );
    }

    return false;
  };

  render() {
    const {
      initialized,
      welcome,
      width,
      hideWelcome,
      draft,
      draftOpen,
      producerDialog,
      shippingLoading,
      marketsLoading,
      changeLocationProblemDialog,
      authDialogStage,
      unavailableItemsDialog,
      checkout,
      clearShippingOptionsDialog,
      forceAuthDialog,
      errorSubscriptionModal,
      isPersonalProducerSelect,
    } = this.props;

    return (
      <React.Fragment>
        {((shippingLoading || marketsLoading) &&
          !PAGES_WITHOUT_LOADER.some((path) => history.location.pathname.split('/').includes(path))) ||
          (!initialized && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
              }}>
              <Spinner size={60} />
            </div>
          ))}

        {initialized && (
          <Router history={history}>
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}>
                <div style={{ flex: 1, background: `#ffffff` }}>
                  <CacheSwitch>
                    <Route exact path="/preview-producer" component={PreviewScene} />

                    {/* Create password via link from email */}
                    <Route exact path="/create-password" component={CreateOrResetPasswordScene} />
                    {/* Restore password via link from email */}
                    <Route exact path="/reset-password" component={CreateOrResetPasswordScene} />
                    {/* Sign in via link */}
                    <Route exact path="/sign-in-via-link" component={SignInViaLinkScene} />
                    {/* Create password via invite link */}
                    <Route exact path={'/invite'} component={InviteScene} />
                    <Route exact path={'/invite/:id'} component={InviteScene} />

                    <Route exact path={'/qr/:hash'} component={QrCodeScene} />

                    <Route exact path="/problem" component={ProblemScene} />
                    <Route exact path="/login-by-url" component={LoginByUrlScene} />
                    {/* Route for authentication by url */}
                    <Route exact path="/producer-info" component={ProducerInfoScene} />
                    <Route exact path="/about-info" component={AboutInfoScene} />
                    <Route exact path="/organizers-info" component={MarketOrganizersInfoScene} />
                    <Route exact path="/about" component={AboutScene} />

                    <PrivateRoute exact path="/order/:id" component={OrderScene} />
                    <PrivateRoute exact path="/order/edit/:id" component={EditOrderScene} />
                    <PrivateRoute exact path="/account" component={AccountScene} />
                    <PrivateRoute exact path="/account/subscription/:id" component={SubscriptionScene} />

                    <PrivateRoute exact path="/track-order/:id" component={TrackOrderScene} />

                    <Route exact path="/markets" component={MarketsScene} />
                    <CacheRoute
                      exact
                      path="/market/:id"
                      component={MarketScene}
                      cacheKey={'MarketScene'}
                      multiple={1} // number of different cached routes
                    />

                    <Route exact path="/producers" component={ProducersScene} />
                    <CacheRoute
                      exact
                      path="/producer/:handle/:market?"
                      component={ProducerScreenSwitcher}
                      cacheKey={'ProducerScreenSwitcher'}
                      multiple={3} // number of different cached routes
                    />
                    <CacheRoute
                      exact
                      path="/personal/:handle"
                      component={PersonalScreenSwitcher}
                      cacheKey={'PersonalScreenSwitcher'}
                      multiple={3} // number of different cached routes
                    />
                    <Route exact path="/personal/product/:id" component={PersonalProductScene} />

                    <Route exact path="/product/:id" component={NewProductScene} />
                    <CacheRoute exact path="/products" component={ProductsTypesScene} />

                    <Route exact path="/products/type/:type" component={ProductsTypeScene} />
                    <Route exact path="/products/type/:type/subtype/:subtype" component={ProductsSubTypeScene} />

                    <Route exact path="/sandbox" component={SandboxScene} />

                    <Route exact path="/contact" component={ContactScene} />
                    <Route exact path="/terms/terms-of-service" component={TermsOfServicesScene} />
                    <Route exact path="/terms/privacy-policy" component={PrivacyPolicyScene} />

                    <Route exact path="/collection">
                      <Redirect to="/markets" />
                    </Route>

                    <Route
                      exact
                      path={`/signup/retail`}
                      component={(props) => <MainScreenSwitcher {...props} openReg={CUSTOMER_TYPES.RETAIL} />}
                    />
                    <Route
                      exact
                      path={`/signup/wholesale`}
                      component={(props) => <MainScreenSwitcher {...props} openReg={CUSTOMER_TYPES.WHOLESALE} />}
                    />

                    <Route exact path="/" component={MainScreenSwitcher} />

                    <Route exact path="/404" component={NotFound} />
                    <Route component={NotFound} />
                  </CacheSwitch>

                  {draft && welcome && !OrderEntity.hasProblemsWithShipping(draft) && (
                    <FellowFarmerDialog
                      closeButton
                      open
                      fullScreen={isWidthDown('xs', width)}
                      onClose={hideWelcome}
                      content={() => <WelcomeBack openDraft={draftOpen} onClose={hideWelcome} draft={draft} />}
                    />
                  )}

                  {/*{shippingDialog && <ShippingOptionsDialog open />}*/}
                  {/*{marketsDialog && <MarketsDialog open />}*/}

                  {producerDialog && <ProducersDialog open />}

                  <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                    <RightSideMenuDialog />
                  </Hidden>

                  {errorSubscriptionModal.open && <ErrorSubscriptionDialog open={errorSubscriptionModal.open} />}

                  {clearShippingOptionsDialog && <ClearShippingOptionsDialog open />}

                  {changeLocationProblemDialog && <ChangeLocationProblemDialog open />}

                  {authDialogStage && <LoginAndSignUpDialog stage={authDialogStage} />}

                  {forceAuthDialog && <ForceLogInAndSignUpDialog />}

                  {unavailableItemsDialog && <UnavailableItemsDialog open ignore={isPersonalProducerSelect} />}

                  {checkout.active && (
                    <CheckoutDialog
                      open
                      disablePickup={isPersonalProducerSelect}
                      forceSaveShippingOptions={isPersonalProducerSelect}
                    />
                  )}

                  <CartDialog open={this.props.cartOpen} disableShippingSelection={isPersonalProducerSelect} />

                  {<InfoDialog />}
                  <SentryErrorHandler />
                </div>

                {this.isShowMobileMenu() && <MobileFooterMenu history={history} openDraft={draftOpen} />}

                {!PAGES_WITHOUT_FOOTER.some((path) => history.location.pathname.split('/').includes(path)) &&
                  !PAGES_WITHOUT_FOOTER.includes(history.location.pathname) && (
                    <GlobalFooter appName={process.env.REACT_APP_NAME} history={history} />
                  )}
              </div>
            </React.Fragment>
          </Router>
        )}
      </React.Fragment>
    );
  }
}

RouterWrapper.propTypes = {
  store: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    welcome: state.welcome.active,
    draft: state.draft.object,
    shippingLoading: state.draft.shippingLoading,
    initialized: state.common.initialized,
    shippingDialog: state.draft.shippingDialog,
    producerDialog: state.draft.producerDialog,
    marketsLoading: state.producer.marketsLoading,
    changeLocationProblemDialog: state.draft.changeLocationProblemDialog,
    marketsDialog: state.market.marketsDialog,
    authDialogStage: state.auth.dialogStage,
    forceAuthDialog: state.auth.forceAuthDialog,
    unavailableItemsDialog: state.draft.unavailableItemsDialog,
    isPersonalProducerSelect: state.draft.isPersonalProducerSelect,
    checkout: state.common.checkout,
    cartOpen: state.draft.active,
    draftError: state.errors.draftError,
    clearShippingOptionsDialog: state.draft.clearShippingOptionsDialog,
    errorSubscriptionModal: state.draft.errorSubscriptionModal,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    draftOpen: (onSuccess) => dispatch(draftOpen(onSuccess)),
    hideWelcome: () => dispatch(hideWelcome()),
    updateDraftError: (error) => dispatch(updateDraftError(error)),
    draftOpenUnavailableItemsDialog: () => dispatch(draftOpenUnavailableItemsDialog()),
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(RouterWrapper));
