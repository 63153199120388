import React from 'react';
import { load } from '../helpers';

const DraftHeaderContainer = load(React.lazy(() => import('./DraftHeaderContainer')));
const OrderHeaderContainer = load(React.lazy(() => import('./OrderHeaderContainer')));
const ShippingChips = load(React.lazy(() => import('./ShippingChips')));
const CarouselSliderContainer = load(React.lazy(() => import('./CarouselSliderContainer')));
const ShippingOptionsDialog = load(React.lazy(() => import('./ShippingOptionsDialog')));
const ChangeLocationProblemDialog = load(React.lazy(() => import('./ChangeLocationProblemDialog')));
const ProductsSearchContainer = load(React.lazy(() => import('./ProductsSearchContainer')));
const MarketsDialog = load(React.lazy(() => import('./MarketsDialog')));
const ProducersDialog = load(React.lazy(() => import('./ProducersDialog')));
const LoginAndSignUpDialog = load(React.lazy(() => import('./LogInAndSignUpDialog')));
const UnavailableItemsDialog = load(React.lazy(() => import('./UnavailableItemsDialog')));
const CartDialog = load(React.lazy(() => import('./CartDialog')));
const ClearShippingOptionsDialog = load(React.lazy(() => import('./ClearShippingOptionsDialog')));
const ListViewContainer = load(React.lazy(() => import('./ListViewContainer')));
const SubTypesByTypesContainer = load(React.lazy(() => import('./SubTypesByTypesContainer')));
const ForceLogInAndSignUpDialog = load(React.lazy(() => import('./ForceLogInAndSignUpDialog')));
const NewHeaderContainer = load(React.lazy(() => import('./NewHeaderContainer')));
const SearchBarWithDelivery = load(React.lazy(() => import('./SearchBarWithDelivery')));
const AddressUpdateDialog = load(React.lazy(() => import('./AddressUpdateDialog')));
const ErrorSubscriptionDialog = load(React.lazy(() => import('./ErrorSubscriptionDialog')));
const UniqueItemsSlider = load(React.lazy(() => import('./UniqueItemsSlider')));
const SideMenu = load(React.lazy(() => import('./SideMenu')));
const ProductsTypesWithMenu = load(React.lazy(() => import('./ProductsTypesWithMenu')));
const ProductPlateContainer = load(React.lazy(() => import('./ProductPlateContainer')));
const ClientSideSortingProductsTypesWithMenu = load(
  React.lazy(() => import('./ClientSideSortingProductsTypesWithMenu'))
);
const SideMenuNew = load(React.lazy(() => import('./SideMenuNew')));
const InfoDialog = load(React.lazy(() => import('./InfoDialog')));
const StickyContainerBox = load(React.lazy(() => import('./StickyContainerBox')));
const RightSideMenuDialog = load(React.lazy(() => import('./RightSideMenuDialog')));
const ProductBoxNew = load(React.lazy(() => import('./ProductBoxNew')));
const ProducerScreenSwitcher = load(React.lazy(() => import('./ProducerScreenSwitcher')));
const PersonalScreenSwitcher = load(React.lazy(() => import('./PersonalScreenSwitcher')));
const PersonalProducerShippingOptions = load(React.lazy(() => import('./PersonalProducerShippingOptions')));
import SentryErrorHandler from './SentryErrorHandler';
import CheckoutDialog from './CheckoutDialog';

export {
  DraftHeaderContainer,
  OrderHeaderContainer,
  CarouselSliderContainer,
  ProductsSearchContainer,
  ShippingChips,
  ShippingOptionsDialog,
  ChangeLocationProblemDialog,
  ProducersDialog,
  MarketsDialog,
  LoginAndSignUpDialog,
  UnavailableItemsDialog,
  CheckoutDialog,
  CartDialog,
  ClearShippingOptionsDialog,
  ListViewContainer,
  SubTypesByTypesContainer,
  ForceLogInAndSignUpDialog,
  NewHeaderContainer,
  SearchBarWithDelivery,
  AddressUpdateDialog,
  ErrorSubscriptionDialog,
  UniqueItemsSlider,
  SideMenu,
  ProductsTypesWithMenu,
  ProductPlateContainer,
  ClientSideSortingProductsTypesWithMenu,
  SideMenuNew,
  InfoDialog,
  StickyContainerBox,
  RightSideMenuDialog,
  ProductBoxNew,
  ProducerScreenSwitcher,
  PersonalScreenSwitcher,
  PersonalProducerShippingOptions,
  SentryErrorHandler,
};
