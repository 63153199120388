import { getPublicRequestConfig, replaceUrlPlaceholder, REQUEST_TYPES } from '../request';
import API_END_POINTS from '../endpoints';
import axios from 'axios';

export const apiCustomerQrCodeVerify = function (code, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.PUBLIC_QR_CODE_VERIFY, 'code', code);
  let config = getPublicRequestConfig(REQUEST_TYPES.GET, url);

  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};
