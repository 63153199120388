import axios from 'axios';
import {
  getRequestConfig,
  REQUEST_TYPES,
  replaceUrlPlaceholder,
  addPriceLevelHeader,
  addAnonymousTokenHeader,
  addAuthorizationTokenHeader,
} from '../request';
import { getCustomerData } from '../../helpers';
import { getStore } from '../../store/configure';
import API_END_POINTS from '../endpoints';

/**
 * Fetches current customer
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerRetrieveCurrent = function (onSuccess, onError) {
  const customer = getCustomerData();
  apiCustomerRetrieve(customer.id, onSuccess, onError);
};

/**
 * Fetch customer by id
 * @param {string} customerId
 * @param {function} onSuccess
 * @param {function} onError
 * @param {string} token - optional token, by default it will use token from local storage
 */
export const apiCustomerRetrieve = function (customerId, onSuccess, onError, token = '') {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_RETRIEVE, 'cr', customerId);
  let config = getRequestConfig(REQUEST_TYPES.GET, url, token);
  // Set request params
  axios(config)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onError && onError(error);
    });
};

/**
 * Patch account info
 * @param {object} customer
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchAccountInfo = function (customer, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_PATCH_ACCOUNT_INFO, 'cr', customer.id);
  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Add address
 * @param {string} type
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddAddress = function (type, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_ADD_ADDRESS, 'at', type);
  let config = getRequestConfig(REQUEST_TYPES.POST, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Add billing address
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddBillingAddress = function (data, onSuccess, onError) {
  apiCustomerAddAddress('billing', data, onSuccess, onError);
};

/**
 * Add shipping address
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddShippingAddress = function (data, onSuccess, onError) {
  apiCustomerAddAddress('shipping', data, onSuccess, onError);
};

/**
 * Patch address
 * @param {string} addressId
 * @param {object} data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchAddress = function (addressId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_PATCH_ADDRESS, 'ad', addressId);
  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  // Set request params
  data = data || {};
  config.data = data;
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Delete address
 * @param {string} addressId
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerDeleteAddress = function (addressId, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_DELETE_ADDRESS, 'ad', addressId);
  let config = getRequestConfig(REQUEST_TYPES.DEL, url);
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Reset password request
 * @param {string} search (username or email)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerResetPasswordRequest = function (search, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_RESET_PASSWORD);
  config.data = {
    search: search,
    url: process.env.REACT_APP_URL + 'reset-password?t=token&c=customerId',
  };
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Create password request
 * @param {string} search (username or email)
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerCreatePasswordRequest = function (search, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_CREATE_PASSWORD);
  config.data = {
    search: search,
    url: process.env.REACT_APP_URL + 'create-password?t=token&c=customerId',
  };

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Checks if customer registered
 * @param {string} search - email or username
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerRegisterCheck = function (search, onSuccess, onError) {
  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_REGISTER_CHECK,
    data: { search: search },
  });

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Register new customer
 * @param {object} data - customer data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerRegisterApply = function (data, onSuccess, onError) {
  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_REGISTER_APPLY,
    data: data,
  });

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Verify customer token
 * @param {string} customerId - customer id
 * @param {string} token - customer token
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerVerifyAccessToken = function (customerId, token, onSuccess, onError) {
  const url = replaceUrlPlaceholder(
    process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_VERIFY_TOKEN,
    'cr',
    customerId
  );

  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.POST,
    url: url,
    data: { token: token },
  });

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Verify customer token from link opened via email
 * @param {string} customerId - customer id
 * @param {string} token - customer token
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerVerifyAccessTokenFromEmailUrl = function (customerId, token, onSuccess, onError) {
  const url = replaceUrlPlaceholder(
    process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_VERIFY_EMAIL_TOKEN,
    'cr',
    customerId
  );

  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.POST,
    url: url,
    data: { token: token },
  });

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Patch customer
 * @param {string} customerId - customer id
 * @param {object} data - request data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatch = function (customerId, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(API_END_POINTS.CUSTOMER_PATCH, 'cr', customerId);
  let config = getRequestConfig(REQUEST_TYPES.PATCH, url);
  config.data = data || {};
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Patch customer
 * @param {string} customerId - customer id
 * @param {token} token - access token
 * @param {object} data - request data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerPatchWithAccessToken = function (customerId, token, data, onSuccess, onError) {
  const url = replaceUrlPlaceholder(process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_PATCH, 'cr', customerId);

  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.PATCH,
    url: url,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: data,
  });

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Create customer account with help of token
 * @param {token} token - access token
 * @param {object} data - request data
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerCreateAccountWithAccessToken = function (token, data, onSuccess, onError) {
  const config = addPriceLevelHeader({
    method: REQUEST_TYPES.POST,
    url: process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_ADD_ACCOUNT,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: data,
  });

  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};

/**
 * Create customer
 * @param {object} data - customer data
 * @return {Promise<any>}
 */
export const apiCustomerCreate = (data) =>
  new Promise((resolve, reject) => {
    const fromCheckout = getStore().getState().auth.fromCheckout ? `fromCheckout=1` : `fromCheckout=0`;
    let config = {
      method: REQUEST_TYPES.POST,
      url: process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_CREATE_BY_ANONYMOUS,
      data: {
        verifyUrl: process.env.REACT_APP_URL + `sign-in-via-link?t=token&${fromCheckout}`,
        ...data,
      },
    };

    config = addPriceLevelHeader(config);
    config = addAnonymousTokenHeader(config);

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Create customer
 * @param {string} customerId
 * @param {string} customerEmail
 * @return {Promise<any>}
 */
export const apiCustomerVerifyEmail = (customerId, customerEmail) =>
  new Promise((resolve, reject) => {
    const fromCheckout = getStore().getState().auth.fromCheckout ? `fromCheckout=1` : `fromCheckout=0`;
    let config = {
      method: REQUEST_TYPES.POST,
      url: process.env.REACT_APP_API_URL + API_END_POINTS.CUSTOMER_VERIFY_EMAIL,
      data: {
        customerId,
        customerEmail,
        verifyUrl: process.env.REACT_APP_URL + `sign-in-via-link?t=token&${fromCheckout}`,
      },
    };

    config = addPriceLevelHeader(config);
    config = addAuthorizationTokenHeader(config);

    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Add discount to customer draft
 * @param {object} data - request data
 * @param {object} data.producerId - required
 * @param {object} data.discountName - required
 * @param {function} onSuccess
 * @param {function} onError
 */
export const apiCustomerAddDiscount = function (data, onSuccess, onError) {
  let config = getRequestConfig(REQUEST_TYPES.POST, API_END_POINTS.CUSTOMER_ADD_DISCOUNT);
  config.data = data || {};
  axios(config)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onError(error);
    });
};
